

.btn-text{
    text-decoration: underline;
    background-color: transparent;
    border:none;
    outline: none;
    text-align: left;
}
.btn-text-primary{
    background-color: $white;
    color:$darkblue;
    vertical-align: middle;
    text-decoration: underline;
}
.btn-flush{
    text-decoration: none;
    background-color: transparent;
    border:none;
    outline: none;
}
.btn-ctaRight{
    background-color: $primary;
    color:$white !important;
    vertical-align: middle;
    text-decoration: none;
    font-size: 0.95em;
	svg{
		transform: scale(1.1);
	}
}
.align-ico{
    vertical-align: middle;
	display: inline-block;
	padding-right:5px;
	.ico{
		vertical-align: middle;
		height: 20px;
		width: 20px;
	}
}
.hidden{
    display: none;
}
h2{
    font-size: 1.3rem;
    font-weight: bolder;
}
.no-y-margin{margin-top:0;margin-bottom:0;}
.no-bottom-margin{margin-bottom:0;}
.evMargin{margin:$margin;}/* padding-bottom: 7rem;*/
.evPadding{padding:$margin !important;}
.m-0{margin:0px !important;}
.mY-1{margin:1rem 0 !important;}
.uppercase{text-transform: uppercase;}
.h-100{height: 100%;}
.noscroll{overflow:hidden;}
.bg_yellow{
    background-color: $yellow !important;
    color:$darkgrey !important;
    a{
        color:$darkgrey !important;
    }
    img{
        filter: invert(30%) sepia(14%) saturate(9%) hue-rotate(350deg) brightness(101%) contrast(97%);
    }
}
.bg_lightblue{
    background-color: $lightblue !important; 
    color:$black !important;
    a{
        color:$black !important;
    }
}
.bg_blue{
    background-color: $darkblue !important; 
    color:$white !important;
    a{
        color:$white !important;
    }
    img{
        filter: invert(100%) sepia(0%) saturate(7446%) hue-rotate(63deg) brightness(104%) contrast(112%);
    }
}
.bg_grey{
    background-color: $darkgrey !important; 
    color:$white !important;
    a{
        color:$white !important;
    }
    img{
        filter: invert(100%) sepia(0%) saturate(7446%) hue-rotate(63deg) brightness(104%) contrast(112%);
    }
}

.bg_red{
    background-color: $red !important; 
    color:$white !important;
    a{
        color:$white !important;
    }
    img{
        filter: invert(100%) sepia(0%) saturate(7446%) hue-rotate(63deg) brightness(104%) contrast(112%);
    }
}
  
.bg_light-red{
    background-color: $light-red !important; 
    color:$white !important;
}

.back_button{
	background-color: transparent;
	border:1px solid $black;
	width:50px;
	height:50px;
	border-radius: 50%;
	text-shadow: none;
	opacity:1;
	color:$black;
	svg{
		transform: scale(1.5);
		margin-left: -4px;
		margin-top: -4px;
	}
}
a.back_button{
	padding: 13px 17px; //hack
}
a.no_style{
	text-decoration: none;
	color:inherit;
}
/* ****************
Sliding Modal
************** */

.sliding-bottom-modal {
	position: fixed;
	z-index: 1031; /*1030 is boostrap fixed zindex*/
	width: 100%;
	top:0;
	left: 0;
	bottom:0;
	right: 0;
	height:100%;
	overflow: hidden;
	transition: all 0.5s;
	background-color:$verylightgrey;
	.close_btn{
	  background-color: $white;
      width:50px;
      height:50px;
	  border-radius: 50%;
	  position: absolute;
	  top: 50%;
	  transform: translateY(-50%);
	  right: 15px;
	  border:none;
	  color: $lightgrey;
	  text-shadow: none;
	  opacity:1;
	  svg{
		  transform: scale(1.5);
		  margin-top: -4px;
	  }
	}
	.modal_header{
		position: relative;
		min-height: 100px;
	  	padding-bottom: 5px;
		.modal-title{
			padding-left:5rem;
			padding-top:35px;
		}
	}
	.modal_body{
		padding:15px;
	  	overflow-y: scroll;
	 	height: 80%;
	 	border-top: 1px solid $lightgrey;
		 .modal_steps{
			border-bottom:1px solid $verylightgrey;
			 .badge{
				width: 115px;
				 padding:10px 15px;
				 margin:0 8px;
				 border:1px solid $lightgrey;
				 color:$lightgrey;
				 &.active{
					 border:1px solid $primary;
					 background-color: $primary;
					 color:$white;
				 }
			 }
		 }
	}
	&.sliding-bottom-close {
		top: 100%;
		height: 0;
	}
  }

#error_dom{
	ul{
		list-style-type: disc !important;
	}
}

  
.button_nav{
	button,a{       
		position: relative;    
		text-decoration: none;
		color:$black;
		border:0;
		outline: none;
		border-bottom: 1px solid $lightgrey;
		width:100%;
		display: block;
		text-align: left;
		padding:15px 0px;
		&:after{
			content:'';
			position: absolute;
			right:5px;
			top:15px;
			width:25px;
			height: 25px;
			background-repeat: no-repeat;
			background-size: contain;
			background-position: center;
			background-image: url('/Assets/img/ico_suivant.png');

		}
		&:last-child{
			border-bottom: none;
		}
	}
}
