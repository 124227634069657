
#root{
    &.body_ios{
        #app{
            padding:20px 0;
        }
        .footer{
            height: 80px;
            padding-bottom: 20px !important;
        }
    }
    
}